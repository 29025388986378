/* === SCHEME CONTAINER === */
.scheme-container {
    padding: 20px;
    background-color: white; /* Light background */
    text-align: center;
  }
 
  
  /* === RESPONSIVE VIDEO CONTAINER === */
  .video-container {
    position: relative;
    max-width: 100%; /* Ensures the video doesn't exceed the container width */
    margin: 0 auto; /* Centers the video */
    overflow: hidden;
  }
  
  .responsive-video {
    width: 100%; /* Makes the video responsive */
    height: auto; /* Maintains aspect ratio */
    border-radius: 10px; /* Optional: Adds rounded corners to the video */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow around the video */
  }
  