/* Grid container */
.reels-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 20px;
  width: 90%;
  max-width: 1300px;
  margin: auto;
  margin-bottom: 30px; /* Adjust as needed */
}

/* Card styles */
.reel-card {
  border-radius: 10px;
  background: #000; /* Fallback for unsupported images or videos */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

/* Image styling */
.reel-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Video styling */
.reel-card__video {
  width: 100%;
  aspect-ratio: 410 / 726; /* Maintain the aspect ratio */
  height: 100%;
  object-fit: cover;
  
}

/* Positioning cards */
.reel-card-1 {
  grid-column: 1;
  grid-row: 1;
}

.reel-card-2 {
  grid-column: 1;
  grid-row: 2;
}

.reel-card-3 {
  grid-column: 3;
  grid-row: 1;
}

.reel-card-4 {
  grid-column: 3;
  grid-row: 2;
}

.reel-card-5 {
  grid-column: 2;
  grid-row: 1 / span 2;
}

/* Responsive Design */
@media (max-width: 768px) {
  .reels-container {
    grid-template-columns: 1fr;
  }

  .reel-card {
    height: auto;
  }
}
