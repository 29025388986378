/* Container and Title */
.admin-gold-rate-container {
    margin: 20px auto;
    padding: 20px;
    max-width: 600px;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .admin-gold-rate-title {
    font-size: 24px;
    color:  #700B00;
    margin-bottom: 10px;
  }
  
  .admin-gold-rate-updated {
    font-size: 14px;
    color:  #700B00;
  }
  
  /* Table */
  .admin-gold-rate-table {
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .admin-gold-rate-header {
    background-color:  #b47902d8;
    color:  #700B00;
    padding: 10px;
    font-weight: bold;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .admin-gold-rate-row:nth-child(even) {
    background-color: #fafafa;
  }
  
  .admin-gold-rate-cell {
    border: 1px solid #ddd;
    padding: 10px;
  }
  
  .admin-gold-rate-input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  /* Button */
  .admin-gold-rate-button-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .admin-gold-rate-submit-button {
    padding: 10px 20px;

    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  

  
  /* Responsive Design */
  @media (max-width: 768px) {
    .admin-gold-rate-container {
      padding: 15px;
    }
  
    .admin-gold-rate-title {
      font-size: 20px;
    }
  
    .admin-gold-rate-input {
      font-size: 12px;
    }
  
    .admin-gold-rate-submit-button {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  