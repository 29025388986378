/* Header Styles */
.header-two {
  width: 100%;
  max-width: 1329px;
  background-color: #fff;
  margin-top: 0.5px;
  position: relative;
  z-index: 10; /* Ensure it is above other content */
  padding: 0px; /* Reduce top and bottom padding */
}

/* Navigation Bar */
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 5px 10px; /* Reduce padding */
  font-size: 16px; /* Slightly smaller font size */
  font-weight: bold;
  position: relative;
}

/* Hamburger Button for Mobile */
.hamburger {
  display: none; /* Shown only on mobile */
  font-size: 30px;
  background: none;
left: 0px;
  border: none;
  color: goldenrod;
  cursor: pointer;
}

/* Mega Menu Styling */
.nav-item {
  position: relative;
  list-style: none;
}

/* Navigation Item Links */
.nav-item a {
  text-decoration: none;
  color: black;
  padding: 5px 10px; /* Reduce padding for links */
  display: block;
  font-weight: bold;
  font-size: 13px; /* Slightly smaller font size */
}
.nav-item a :hover{
color:goldenrod;}
/* Dropdown Menu (Mega Menu) */
.dropdown {
  display: none;
  position: absolute;
  flex-wrap: wrap; /* Allow items to wrap into multiple rows */
  top: 100%;
  left: 0;
  background-color: #fff;
  width: 560px; /* Stretch across full width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  box-sizing: border-box;

  overflow-x: hidden;
  z-index: 20;
}

/* Show Dropdown on Hover */
.nav-item:hover .dropdown {
  display: flex; /* Display as flexbox for alignment */

}

/* Dropdown Content */
.dropdown li {
  flex: 1 1 120px; /* Reduced width for each item */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-align content */
  margin: 5px; /* Reduce spacing between items */
  list-style: none;
  text-align: center; /* Center text */
  max-width: 120px; /* Ensure text and image fit within this width */
}

.dropdown li img {
  width: 120px; /* Image width */
  height: 120px; /* Image height */
  object-fit: cover;
  border-radius: 8px; /* Rounded corners */
  margin-bottom: 5px; /* Spacing below the image */
}

.dropdown li a {
  font-size: 13px; /* Slightly smaller font size */
  font-weight: bold;
  color: #333;
  word-wrap: break-word; /* Break long words */
  text-align: center; /* Center-align the text */
  max-width: 100%; /* Ensure text doesn't overflow */
}

.dropdown li:hover {
  background-color: #f9f9f9; /* Highlight on hover */
}

.dropdown li:hover {
  background-color: #f9f9f9;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hamburger {
    display: block; /* Show hamburger for mobile */
  }

  .nav {
    flex-direction: column;
  }

  .nav-list {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
   
  }

  .nav-list.open {
    display: flex;
  }
  .nav-item a {
  color:black;
}
  .dropdown {
    display: none; /* Hide dropdowns on mobile */
  }

  .nav-item:hover .dropdown {
    display: none; /* Prevent hover effect on mobile */
  }

  .dropdown li {
    flex-wrap: wrap; /* Wrap items */
    text-align: left; /* Align text to left */
  }

  .dropdown li img {
    display: none; /* Hide images in dropdown on mobile */
  }
}