.custom-dropdown-container {
    width: 100%;
    max-width: 300px; /* Adjust this value as needed */
    margin: 5px auto;
    position: relative;
  }
  
  .custom-dropdown {
    position: relative;
    background: white;
    border: 1px solid #b57f00; /* Updated border color */

    border-radius: 5px;
    padding: 10px;
    height:18px;
    width: 280px;
    cursor: pointer;
    z-index: 100; /* Ensure dropdown appears above other components */
    overflow: visible;
  }
  
  .custom-dropdown.open {
    border-color: #b57f00; /* Match border color when open */
  }
  
  .custom-dropdown-current {
    font-size: 16px;
  
    color: #b57f00;
    display: block; /* Ensures the selected option is always visible */
  }
  
  .custom-dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #b57f00; /* Updated border color */
    border-radius: 5px;
    z-index: 1000; /* Higher value to ensure it overlays other content */
    max-height: 200px;
    overflow-y: auto;
    padding: 5px;
  }
  
  .custom-dropdown-searchbox {
    width: 100%; /* Ensures the search box fits its container */
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 10px; /* Space between search and options */
  }
  
  .custom-dropdown-options {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .custom-dropdown-option {
    padding: 10px;
    cursor: pointer;
    color: #333;
  }
  
  .custom-dropdown-option:hover {
    background: #f0f0f0;
    color: #b57f00; /* Match theme color */
  }
  