.features-section {
  text-align: center;
  margin: 20px auto;
}

.features-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.features-row {
  display: flex;
  flex-wrap: wrap; /* Ensure cards wrap on smaller screens */
  gap: 15px; /* Reduced gap between cards */
  justify-content: center; /* Center align cards */
}

.features-card {
  border-radius: 10px;
  filter: drop-shadow(0 5px 10px 0 #ffffff);
  width: 180px; /* Reduced width */
  height: 160px; /* Reduced height */
  background-color: #ffffff;
  padding: 15px; /* Reduced padding */
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition: 0.6s ease-in;
}

.features-card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -15px;
  right: -15px;
  background: rgb(182, 127, 0);
  height: 200px;
  width: 25px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}
.sub-heading{
  font-size:12px;

}
.features-card:hover::before {
  transition-delay: 0.2s;
  transform: scale(40);
}

.features-card:hover {
  color: #ffffff;
}

.features-card-image {
  margin-bottom: -10px;
  height: 50px; /* Adjusted image size */
  width: 50px; /* Adjusted image size */
}

.features-card-title {
  margin: 5px 0;
  font-size: 14px; /* Reduced font size */
}

.features-card-description {
  margin: 5px 0;
  font-size: 12px; /* Reduced font size */
}

/* Responsive Design */
@media (max-width: 768px) {
  .features-card {
    width: 160px;
    height: 140px;
  }
  .sub-heading{
    font-size:10px;
  
  }
  
}

@media (max-width: 480px) {
  .features-card {
    width: 140px;
    height: 120px;
  }
  .sub-heading{
    font-size:12px;
  
  }
  
  .features-card-image {
    margin-bottom: -10px;
    height: 40px;
    width: 40px;
  }

  .features-card-title {
    font-size: 12px;
  }

  .features-card-description {
    font-size: 10px;
  }
}
