/* Main container */
.schemes-container {
  margin: 0 auto;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

/* Content container */
.schemes-content {
  width: 100%;
  max-width: 1300px;
  text-align: justify;
}

/* Section styles */
.schemes-section {
  margin-bottom: 20px; /* Spacing between sections */
}

/* Heading styles */
.schemes-heading {
  font-size: 1.5em;
  font-weight: bold;
  color: #544114;
  border-bottom: 2px solid #c67e00;
  margin-bottom: 10px;
  text-align: left;
  display: inline-block;
}

/* Subheading styles */
.schemes-subheading {
  font-size: 1.2em;
  font-weight: bold;
  color: #544114;
  margin-bottom: 15px;
  text-align: left;
}

/* Scheme item styles */
.schemes-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four items per row */
  gap: 20px; /* Increase gap for better spacing */
  width: 100%;
  margin-top: 20px;
}

.scheme-item {
  background-color: rgb(255, 252, 244);
  border: 2px solid #c67e00;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px; /* Set a max-width for the cards */
  margin: 0 auto; /* Center the cards */
  min-height: 350px; /* Ensure a consistent height for the cards */
  justify-content: space-between; /* Space content evenly */
}

.scheme-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.scheme-item-image img {
  width: 100%;
  height: 200px; /* Set a fixed height for the images */
  object-fit: cover; /* Ensure images maintain aspect ratio without distortion */
  border-radius: 8px;
}

.scheme-item-text {
  text-align: center;
}

.scheme-title {
  font-size: 1.2em;
  font-weight: bold;
  color: #c67e00;
}

/* Link style */
.read-more-link {
  font-size: 1em;
  color: #c67e00;
  text-decoration: none;
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .schemes-list {
    grid-template-columns: repeat(2, 1fr); /* Two cards per row for medium screens */
  }
}

@media (max-width: 768px) {
  .schemes-list {
    grid-template-columns: 1fr; /* Single card per row on small screens */
  }

  .scheme-item {
    min-height: 300px; /* Adjusted height for mobile */
  }

  .scheme-item-content {
    align-items: center; /* Align items at the center */
  }

  .scheme-item-image img {
    width: 100%;
    height: 200px; /* Keep a consistent height for images */
  }
}
