/* Main container for the shop page */
.shop-container {
    padding: 2rem;
    background-color: white;
  }
  
  /* Navigation styles */
  .shop-nav {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  .shop-nav button {
    padding: 0.8rem 2rem;
    font-size: 1rem;
    cursor: pointer;
    background: linear-gradient(90deg, #614100cc, #d89700, #6b4700c4);
    /* Gradient from dark to light to dark */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 5px;
    color: white;
    transition: background-color 0.3s ease;
  }
  
 
  .shop-nav button:hover {
    background-color: #700b00;
  }
  

  .shop-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* Removed the perspective property */
    transition: transform 0.3s ease-in-out; /* Keep the smooth transition */
  }

/* Ensure consistent image size */
.shop-card img {
  width: 100%; /* Adjust to card width */
  height: auto;
  border-radius: 5px;
  object-fit: cover; /* Avoid distortion */
}

/* Add 3D hover effect */
.shop-card:hover img {
  transform: rotateY(10deg) rotateX(5deg);
}
.card-name {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: #700B00; /* Updated text color */
}
  /* Remove the 3D hover effect */
  .shop-card:hover {
    /* Remove the transform properties for 3D effect */
    transform: none; /* No transform effect */
  }
  
.shop-card {
  margin: 0; /* Remove any extra margin */
}

.shop-container {
  padding: 1rem; /* Adjust the padding for less space */
}

.shop-heading {
  margin-bottom: 1rem; /* Reduce the space between heading and grid */
}
/* Grid Layout for all devices */
.shop-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust column sizes */
  gap: 1.6rem;
  max-width: 1200px;
  margin: auto;
}
  /* Heading Styles */
.shop-heading {
  margin-bottom: 2rem; /* Space between heading and navigation */
}
.shop-explore-more {
  text-align: center;
  margin-top: 2rem;
}

.shop-explore-more button {
  padding: 1rem 2rem;
  font-size: 1rem;
  background: linear-gradient(90deg, #614100cc, #d89700, #6b4700c4);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: blink 1.5s infinite; /* Apply the blinking animation */
  transition: transform 1s;
}

.shop-explore-more button:hover {
  transform: scale(1.05);
}

/* Blinking animation */
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.one h1 {
  text-align: center;

  padding-bottom: 5px;
}
.one h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: #700B00;
}
.one h1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 25px;
  left: 50%;
  margin-left: -50px;
  background-color: #700B00;
}

/* Mobile view */
@media (max-width: 768px) {
  .shop-nav button {
    padding: 0.4rem 1.0rem; /* Maintain reduced padding */
    font-size: 0.7rem; /* Keep the reduced font size */
  }

  .shop-grid {
    grid-template-columns: repeat(3, 1fr); /* Keep 3 columns */
    gap: 0.5rem; /* Maintain the gap */
    padding: 0; /* No padding */
  }

  .shop-card img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .shop-card {
    margin: 0; /* Ensure no extra margin */
  }

  .shop-container {
    padding: 1rem; /* Less space padding */
  }

  .shop-heading {
    margin-bottom: 1rem; /* Maintain reduced space */
  }

  /* Ensure no transition or animation effects */
  .shop-grid, .shop-card, .shop-nav button, .shop-container {
    transition: none !important; /* Remove any transition effects */
  }
}


  
  /* Tablet and larger screens */
  @media (min-width: 1024px) {
    .shop-grid {
      grid-template-columns: repeat(3, 1fr); /* 3 columns on larger screens */
    }
  }
