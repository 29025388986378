
.marquee-wrapper {
  margin: 0;
  padding: 0;
  position: static; /* Keeps it at the top during scroll */
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: white; /* Adjust if needed */
  overflow: hidden;
  
}

.marquee-container-custom {
  position: relative;
  width: 100%; /* Full width */
  height: 20px;
  background-color: maroon;
  color: white;
  overflow: hidden;
  border-radius: 0; /* No rounding */
  display: flex;
  align-items: center;
  justify-content: center;
}

.marquee-text-custom {
  display: inline-block;
  white-space: nowrap;
  font-size: 1.0rem;

  animation: scrollLeft 40s linear infinite; /* Adjust the duration as needed */
}

@keyframes scrollLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
