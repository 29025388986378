/* Main container */
.about-hero-container {
  margin: 0 auto;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

/* Content container */
.about-hero-content {
  width: 100%;
  max-width: 1300px;
}

/* Section styles */
.about-section {
  margin-bottom: 20px; /* Spacing between sections */
}

.about-hero-image {
  text-align: center;
  margin-bottom: 20px;
  width: 100%; /* Ensure the image container doesn't overflow */
}
/* Keyframe for slide-in effect */
@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Image Section */
.about-hero-image {
  text-align: center;
  margin-bottom: 20px;
  width: 100%; /* Ensure the image container doesn't overflow */
  animation: slideInFromLeft 1s ease-out; /* Apply animation */
}

.about-hero-img {
  width: 100%;
  height: auto;
  max-width: 800px;
  max-height: 400px;
  object-fit: cover;
  border-radius: 5px;
  display: block; /* Prevent image from overflowing its container */
  margin: 0 auto;
}


.about-stretch {
  width: 100%;
  height: auto;
  max-width: 100%; /* Ensure it fits smaller screens */
  border-radius: 5px; /* Optional: round corners on mobile */
}
/* Heading styles */
.about-hero-heading {
  font-size: 25px;
  font-weight: bold;
  color: #544114;
  border-bottom: 2px solid #c67e00;
  margin-bottom: 10px;
  text-align: left; /* Left-align the heading */
  display: inline-block; /* Restrict the border to the text width */
}

/* Paragraph styles */
.about-section p {
  text-align: justify;
  font-size: 18px;
  line-height: 1.5;
  color: black;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .about-hero-container {
    padding: 10px;
  }

  .about-hero-img {
    max-height: 250px;  /* Reduce the image height for mobile */
    object-fit: cover;
    border-radius: 5px;  /* Optional: adjust border radius for mobile */
    width: 100%; /* Ensure image is fully responsive */
    height: auto; /* Maintain aspect ratio */
  }

  .about-hero-heading {
    font-size: 1.5rem;  /* Make the heading font smaller on mobile */
    text-align: center;
  }

  .about-section {
    padding: 0 10px;
  }

  .about-hero-content {
    padding: 10px;
  }

  .about-section p {
    font-size: 0.9rem;  /* Adjust font size for mobile */
  }
}
