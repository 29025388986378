/* Main container */
.future-gold-container {
    margin: 0 auto;
    padding: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
  }
  
  /* Content container */
  .future-gold-content {
    width: 100%;
    max-width: 1300px;
  }
  
  /* Section styles */
  .future-gold-section {
    margin-bottom: 20px; /* Spacing between sections */
  }
  
  /* Heading styles */
  .future-gold-heading {
    font-size: 1.5em;
    font-weight: bold;
    color: #544114;
    border-bottom: 2px solid #c67e00;
    margin-bottom: 10px;
    text-align: left; /* Left-align the heading */
    display: inline-block; /* Restrict the border to the text width */
  }
  
/* Paragraph styles */
.future-gold-text {
  text-align: justify; /* Ensures text is justified */
  font-size: 1em;
  line-height: 1.5;
  color: black;
  margin: 0; /* Optional: Remove default margins for uniformity */
}

  
  /* List styles */
  .future-gold-list {
    padding-left: 20px;
    list-style-type: disc;
  }
  
  .future-gold-list li {
    margin-bottom: 8px;
  }
  @media (max-width: 1024px) {
    .future-gold-text {
      font-size: 0.8em;
    }
    .future-gold-text {
      font-size: 0.8em;
    }
    
  
  }
  /* Responsive design */
  @media (max-width: 768px) {
    .future-gold-heading {
      font-size: 1.2em;
    }
  
    .future-gold-text {
      font-size: 0.95em;
    }
  
    .future-gold-list {
      padding-left: 15px;
    }
  }
  