.Gridlayout {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.about-image {
  width: 100%; /* Ensures the container takes up the full width */
  display: flex; /* Flex container to center image */
  justify-content: center; /* Center image horizontally */
  align-items: center; /* Center image vertically if needed */
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  transition: transform 0.3s ease; /* Smooth zoom effect */
}

/* Adjust image size and center it */
.about-image img {
  max-width: 80%; /* Limit the width of the image */
  height: auto; /* Maintain the aspect ratio */
  margin: 0 auto; /* Center the image horizontally */
  display: block; /* Ensures the image is treated as a block-level element */
  border-radius: 10px;
  opacity: 1;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

/* Keyframe for slide-in effect */
@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}



/* Trigger animation on hover */
.about-image:hover img {
  animation: slideInFromLeft 0.7s ease-out;
  animation-fill-mode: forwards; /* Ensures animation only runs once */
  transform: none; /* Reset transform */
}


/* Zoom-out effect while loading */
.about-image.loading img {
  transform: scale(1.2); /* Zoom-out effect */
  opacity: 0.6; /* Dim image while loading */
}

/* Reset to normal once loaded */
.about-image img:not(.loading) {
  transform: scale(1); /* Reset scale */
  opacity: 1;
}

