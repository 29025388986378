/* Global styles for contact container */
.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: 'Playfairdisplay', 'sans-serif';
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

/* Overlay for the background */
.contact-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black overlay */
  z-index: 1;
}

/* Ensures content appears above the overlay */
.contact-container > * {
  z-index: 2;
}

/* Background image settings */
.contact-container {
  background-size: cover;
  background-position: center;
}

/* Form wrapper with zoom-out animation */
.contact-form-wrapper {
  width: 100%;
  max-width: 800px;
  background-color: rgba(238, 198, 100, 0.4);
  backdrop-filter: blur(0.5px);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
  animation: zoomOut 0.8s ease-out; /* Apply zoom-out animation */
}

/* Zoom-out animation keyframes */
@keyframes zoomOut {
  0% {
    transform: scale(1.2); /* Start with a slightly bigger size */
    opacity: 0; /* Start with opacity 0 */
  }
  100% {
    transform: scale(1); /* End at the normal size */
    opacity: 1; /* Fully visible */
  }
}

/* Form heading */
.contact-form-wrapper h2 {
  margin-bottom: 20px;
  color: white;
  font-family: 'Mier demi', sans-serif;
}

/* Form row layout */
.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 15px;
}

.form-group {
  flex: 1;
  min-width: 0;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input,
textarea,
select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

input:focus,
textarea:focus,
select:focus {
  border-color: #700b00;
  outline: none;
}

/* Comments textarea */
textarea {
  resize: none;
}

/* Form action buttons */
.form-actions {
  display: flex;
  justify-content: space-between;
}

.cancel-button,
.submit-button {
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cancel-button {
  background-color: #ccc;
  color: #000;
}

.cancel-button:hover {
  background-color: #bbb;
}

.submit-button {
  background-color: #700b00;
  color: #fff;
}

.submit-button:hover {
  background-color: #500800;
}

/* Mobile responsive design */
@media (max-width: 768px) {
  .contact-form-wrapper {
    padding: 15px;
  }

  .form-row {
    flex-direction: column;
  }

  .form-actions {
    flex-direction: column;
    gap: 10px;
  }

  .cancel-button,
  .submit-button {
    width: 100%;
  }
}
