/* UpArrow.css */

.up-arrow-icon {
    width: 40px; /* Adjust the width as needed */
    height: 40px; /* Adjust the height as needed */
    color: white; /* Icon color */
    background-color: goldenrod;
    border-radius: 50%; /* Make it round */
    cursor: pointer;
    position: fixed;
    bottom: 70px; /* Distance from the bottom */
    left: 10px; /* Distance from the right */
    z-index: 1000; /* Ensures it's above other elements */
    transition: all 0.3s ease-in-out;
    animation: glow 1.5s ease-in-out infinite; /* Apply glowing effect */
  }
  
  /* Hover effect */
  .up-arrow-icon:hover {
    transform: translateY(-5px); /* Hover effect */
    box-shadow: 0 0 15px 3px rgba(255, 255, 0, 0.6); /* Yellow glow on hover */
  }
  
  /* Active effect */
  .up-arrow-icon:active {
    transform: translateY(2px); /* Slightly move when clicked */
  }
  
  /* Glowing effect */
  @keyframes glow {
    0% {
      box-shadow: 0 0 5px 3px rgba(255, 255, 0, 0.5); /* Initial glow */
    }
    50% {
      box-shadow: 0 0 20px 10px rgba(255, 255, 0, 0.9); /* Bright glow */
    }
    100% {
      box-shadow: 0 0 5px 3px rgba(255, 255, 0, 0.5); /* Return to initial glow */
    }
  }
  