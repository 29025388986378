/* Gold Hero Video Container */
.gold-hero-video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 21.22%; /* Maintain 1365x290 aspect ratio (290/1365 = ~0.2122) */
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 40px;
  z-index: 5;
}

/* Gold Hero Video */
.gold-hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the container */
  object-position: center; /* Center the video */
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
  .gold-hero-video-container {
    padding-top: 56.25%; /* Adjust for a more appropriate aspect ratio on mobile if needed */

  }

}
@media (max-width: 480px) {
.gold-hero-image {
  object-fit: fill;}
}