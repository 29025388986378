/* Container for the diamond layout */
.diamond-page {
  margin-top: 30px;;
}

.diamond-container {
  display: grid;
  grid-template-columns: 2fr 1fr; /* Full column and a smaller column */
  gap: 10px; /* Add some spacing between columns */
  width: 100%;
  max-width: 100%; /* Ensure it doesn't exceed the viewport width */
  margin: 0 auto; /* Center the container */
  padding: 10px; /* Reduce padding for a tighter layout */
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* === BASE HEADING === */ 
.one {
  text-align: center;
  margin-bottom: 10px; /* Adds space between the heading and cards */
}

.one h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: 'Playfair Display', serif; /* Font applied */
  font-weight: 400;
  font-size: 25px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.one h1 span {
  display: block;
  font-size: 0.5em;
  line-height: 1.3;
}

.one h1 em {
  font-style: normal;
  font-weight: 600;
}

.one h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: #700B00;
}

.one h1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 15px;
  left: 50%;
  margin-left: -50px;
  background-color: #700B00;
}


/* Full-width image column */
.diamond-full {
  grid-column: 1 / 2;
}

.diamond-grid {
  display: grid;
  grid-template-rows: 1fr 1fr; /* Two rows of equal height */
  gap: 10px; /* Add spacing between rows */
  grid-column: 2 / 3;
}

/* Images */
.diamond-image {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensure the image covers its container */
  border-radius: 5px; /* Optional: Add rounded corners */
}

/* Responsive Design */
@media (max-width: 768px) {
  .diamond-container {
    grid-template-columns: 1fr; /* Single column layout */
    grid-template-rows: auto auto; /* Stack rows */
  }
  .one h1 {

    font-size: 20px;
   
  }
  .diamond-full, .diamond-grid {
    grid-column: 1 / -1; /* Full width for all sections */
  }

  .diamond-grid {
    grid-template-rows: 1fr 1fr; /* Maintain two rows for images */
  }
}
