/* Gold Rate Tag Styles */
.gold-rate-container {
    position: absolute;
    top: 520px;
    right: 8px;
    z-index: 10;
  }
  
  .gold-rate-tag {
    background: gold;
    color: black;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  }
  
  .gold-rate-dropdown {
    margin-top: 5px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .gold-rate-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .gold-rate-table th,
  .gold-rate-table td {
    border: 1px solid #ddd;
    padding: 5px;
    text-align: left;
  }
  
  .gold-rate-table th {
    background: #700B00;
    color: white;
  }
  
  @media (min-width: 769px) {
    .gold-rate-container {
      display: none; /* Hide on larger screens */
    }
  }
  