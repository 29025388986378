.hero-carousel {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 31.48%; /* Maintain 5400x1700 aspect ratio */
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 40px;
 
}

/* Hero Images */
.hero-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0; /* Start hidden */
  transition: opacity 1s ease-in-out;
}

/* Active Image */
.hero-image.active {
  opacity: 1; /* Fully visible */
}

/* Navigation buttons */
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  cursor: pointer;
  z-index: 10;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.5rem;
}

.carousel-control-prev {
  left: 10px;
}

.carousel-control-next {
  right: 10px;
}

/* Carousel Indicators */
.carousel-indicators {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 0;
  list-style: none;
  position: absolute;
  bottom: 20px;
  width: 100%;
  z-index: 10;
}

/* Style for the individual indicator buttons */
.indicator {
  width: 5px; /* Size of the circle */
  height: 5px;
  border-radius: 50%; /* Makes the button circular */
  background-color: white; /* Default color for inactive indicators */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0;
  margin: 0;
  display: inline-block;
}

/* Active indicator style */
.indicator.active {
  background-color: #c67e00; /* Color for the active indicator */
}

/* Hover effect for indicators */
.indicator:hover {
  background-color: white; /* Slightly darker when hovered */
}


/* Mobile View Adjustments */
@media (max-width: 768px) {
  .hero-carousel {
    height: 34vh;
    padding-bottom: 0;
  }

  .hero-image {
    object-position: center;
    object-fit: contain;
  }
}
@media (max-width: 480px) {
  .hero-carousel {
    height: 34vh;
    padding-bottom: 0;
  }

  .hero-image {
    object-position: center;
    object-fit: contain;
  }
}